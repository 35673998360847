import type { DashboardLibraryItem } from '~/types'
import type { HubDashboardBaseModal, HubDashboardMetadata } from '~/types/configuration'

export const useDashboardStore = defineStore('dashboard', () => {
  const organisationStore = useOrganisationStore()
  const { currentOrganisation } = storeToRefs(organisationStore)

  // refs
  const currentDashboardId: Ref<string | undefined> = ref()
  const unmodifiedDashboardsList: Ref<Array<HubDashboardBaseModal>> = ref([])
  const dashboardByOrganisationList: Ref<Array<{ rootOrgId: number, dashboards: Array<HubDashboardBaseModal> }>> = ref([])

  // computed
  const allDashboards: ComputedRef<Array<HubDashboardBaseModal>> = computed(() => {
    return dashboardByOrganisationList.value.flatMap(item => item.dashboards) || []
  })
  const currentOrganisationDashboards: ComputedRef<Array<HubDashboardBaseModal>> = computed(() => {
    return dashboardByOrganisationList.value
      .find(item => item.rootOrgId === currentOrganisation.value?.item.organisationNodeId)?.dashboards
      || []
  })
  const currentOrganisationCommentDashboards: ComputedRef<Array<HubDashboardBaseModal>> = computed(() => {
    return currentOrganisationDashboards.value.filter(x => x.type === 'comments')
  })
  const currentDashboard: ComputedRef<HubDashboardBaseModal | undefined> = computed(() => allDashboards.value.find(x => x.id === currentDashboardId.value))
  const currentUnModifiedDashboard: ComputedRef<HubDashboardBaseModal | undefined> = computed(() =>
    unmodifiedDashboardsList.value.find(x => x.id === currentDashboardId.value)
  )
  const userDefaultDashboard: ComputedRef<HubDashboardBaseModal | undefined> = computed(() => {
    return allDashboards.value.find(x => x.isDefault)
  })
  const groupDefaultDashboards: ComputedRef<Array<HubDashboardBaseModal>> = computed(() => {
    return allDashboards.value
      .filter(x => x.groups?.length && x.groups.some(group => group.isDefault))
      .sort((a, b) => {
        // @ts-expect-error - groups are guaranteed to have a default group as we're filtering above
        return a.groups.find(group => group.isDefault).ordinal - b.groups.find(group => group.isDefault).ordinal
      })
  })
  const currentOrganisationGroupDefaultDashboards: ComputedRef<Array<HubDashboardBaseModal>> = computed(() => {
    return currentOrganisationDashboards.value
      .filter(x => x.groups?.length && x.groups.some(group => group.isDefault))
      .sort((a, b) => {
        // @ts-expect-error - groups are guaranteed to have a default group as we're filtering above
        return a.groups.find(group => group.isDefault).ordinal - b.groups.find(group => group.isDefault).ordinal
      })
  })

  // functions
  function setCurrentDashboardId(dashboardId: string) {
    currentDashboardId.value = dashboardId
  }
  async function fetchDashboardsForOrganisation(
    rootOrgId: number,
    forceUpdate: boolean = false,
    updateStore: boolean = true,
    query?: object):
    Promise<Array<DashboardLibraryItem>> {
    if (dashboardByOrganisationList.value.length && !forceUpdate) {
      const orgDashboards = dashboardByOrganisationList.value.find(item => item.rootOrgId === rootOrgId)
      if (orgDashboards) {
        return orgDashboards.dashboards
      }
    }

    const dashboards = await $hubFetch<{
      count: number
      items: Array<DashboardLibraryItem>
    }>(`/api/v4/organisations/${rootOrgId}/dashboards`, { query })

    if (updateStore) {
      dashboardByOrganisationList.value.push({
        rootOrgId: rootOrgId,
        dashboards: dashboards?.items
      })
      if (dashboards.items) {
        unmodifiedDashboardsList.value.push(...dashboards.items)
      }
    }

    return dashboards?.items
  }

  async function fetchDashboard(dashboardId: string, forceUpdate: boolean = false): Promise<HubDashboardBaseModal> {
    const dashboard = allDashboards.value.find(x => x.id === dashboardId)
    if (dashboard && !forceUpdate) {
      return dashboard
    }

    // Really shouldn't need this any more because DashboardLibraryItem should have all the data we need but just in case
    const data = await $hubFetch<HubDashboardBaseModal>(`api/v4/dashboards/${dashboardId}`)

    if (!data) {
      throw createError({ statusCode: 404, statusMessage: 'Dashboard Not Found', fatal: true })
    }

    updateDashboard(dashboardId, data.rootOrganisationNodeId, data)
    return data
  }

  async function fetchDashboardMetadata(dashboardId: string, forceUpdate: boolean = false) {
    const dashboard = allDashboards.value.find(x => x.id === dashboardId)
    if (!dashboard) {
      throw Error('Dashboard Not Found')
    }

    if (Object.keys(dashboard).includes('metadata') && !forceUpdate) {
      return dashboard.metadata
    }

    const metadata = await $hubFetch(`api/v4/dashboards/${dashboardId}/metadata`) as HubDashboardMetadata

    updateDashboard(dashboardId, dashboard.rootOrganisationNodeId, metadata, 'metadata')
  }

  async function updateDashboard<HubDashboardItemKey extends keyof HubDashboardBaseModal>(
    dashboardId: string,
    rootOrgId: number,
    updateBody: HubDashboardBaseModal | HubDashboardBaseModal[HubDashboardItemKey],
    itemKey?: HubDashboardItemKey
  ) {
    let organisationIndex = 0
    const organisationDashboards = dashboardByOrganisationList.value.find((item, index) => {
      organisationIndex = index
      return item.rootOrgId === rootOrgId
    })

    let dashboardIndex = -1
    let dashboardList: Array<HubDashboardBaseModal> = organisationDashboards?.dashboards || []
    if (!organisationDashboards) {
      dashboardList = await fetchDashboardsForOrganisation(rootOrgId, true, true)
      organisationIndex = dashboardByOrganisationList.value.findIndex(item => item.rootOrgId === rootOrgId)
    }

    dashboardIndex = dashboardList?.findIndex(item => item.id === dashboardId)
    if (dashboardIndex === -1) {
      if (!dashboardList || !dashboardList.length || itemKey) {
        throw Error('Dashboard Could Not Be Updated')
      }

      dashboardList.push(updateBody as HubDashboardBaseModal)
      return updateBody as HubDashboardBaseModal
    }

    if (itemKey) {
      dashboardList[dashboardIndex] = {
        ...dashboardList[dashboardIndex],
        [itemKey]: updateBody as HubDashboardBaseModal[HubDashboardItemKey]
      }
    } else {
      dashboardList[dashboardIndex] = updateBody as HubDashboardBaseModal
    }

    if (organisationIndex < 0) {
      dashboardByOrganisationList.value.push({
        rootOrgId: rootOrgId,
        dashboards: dashboardList
      })
    } else {
      dashboardByOrganisationList.value.splice(organisationIndex, 1, {
        rootOrgId: rootOrgId,
        dashboards: dashboardList
      })
    }

    return dashboardList[dashboardIndex]
  }
  function updateUnModifiedDashboard(dashboardId: string, updateBody: HubDashboardBaseModal) {
    const dashboardIndex = unmodifiedDashboardsList.value.findIndex(item => item.id === dashboardId)
    if (dashboardIndex === -1) {
      throw Error('Dashboard Could Not Be Updated')
    }

    unmodifiedDashboardsList.value[dashboardIndex] = updateBody
  }

  return {
    // only used internally but need to be returned
    currentDashboardId,
    dashboardByOrganisationList,
    unmodifiedDashboardsList,

    // public things
    userDefaultDashboard,
    groupDefaultDashboards,
    currentDashboard,
    currentUnModifiedDashboard,
    currentOrganisationDashboards,
    currentOrganisationCommentDashboards,
    currentOrganisationGroupDefaultDashboards,
    allDashboards,

    fetchDashboard,
    fetchDashboardsForOrganisation,
    fetchDashboardMetadata,
    updateDashboard,
    updateUnModifiedDashboard,
    setCurrentDashboardId
  }
})
