import type { HubUser } from '~/types'
import { useUserStore } from '~/stores/user.store'

interface HubUserWithPermissions extends HubUser {
  permissions: string[]
}

type HubPermission = string | { required: boolean, permission: string }

function userHasRequiredPermissions(
  user: HubUserWithPermissions,
  permissions: Array<HubPermission>,
  allRequired: boolean = true
) {
  if (!permissions) {
    return true
  }

  // If all permissions are required, return true if the user has all of the permissions
  if (allRequired) {
    return permissions.every(p => user.permissions.includes(p instanceof Object ? p.permission : p))
  }

  // if the permissions are objects, check if each permission is required or not
  // return true if the user has all of the required permissions and at least one of the optional permissions
  if (permissions.some(p => p instanceof Object)) {
    const requiredPermissions = permissions.filter(p => p instanceof Object ? p.required : p)
    const otherPermissions = permissions.filter(p => p instanceof Object ? !p.required : p)

    return requiredPermissions.every(p => user.permissions.includes(p instanceof Object ? p.permission : p))
      && otherPermissions.some(p => user.permissions.includes(p instanceof Object ? p.permission : p))
  }

  // if all the permissions are not required, return true if the user has at least one of the permissions
  return permissions.some(p => user.permissions.includes(p instanceof Object ? p.permission : p))
}

export default function ({
  permissions,
  user = undefined,
  allRequired = true,
  organisationId = undefined,
  customFunction = undefined
}: {
  user?: HubUser | undefined
  permissions: string | Array<HubPermission>
  allRequired?: boolean
  organisationId?: string | number
  customFunction?: (
    user: HubUserWithPermissions,
    permission: string | Array<HubPermission>)
  => boolean
}) {
  // If no user is provided, get the current user from the store
  if (!user) {
    const { currentUser } = storeToRefs(useUserStore())
    user = currentUser.value
  }

  // If user is still not set, return as we cannot check permissions
  if (!user || !user.permissions) return false

  const userWithPermissions = user as HubUserWithPermissions

  // If no permission is provided, then no need to check, permission is not required
  if (!permissions && !organisationId && !customFunction) return true

  // If custom function is provided, use it to check permissions
  if (customFunction) return customFunction(userWithPermissions, permissions)

  // If permission is a string, convert it to an array
  if (typeof permissions === 'string') permissions = [permissions]

  // Check if the user has the required permissions
  return userHasRequiredPermissions(userWithPermissions, permissions, allRequired)
}
