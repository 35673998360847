<template>
  <div :class="parentClasses">
    <div class="flex min-h-full flex-col items-center justify-center bg-white text-center">
      <img
        src="~/assets/img/component-error.svg"
        style="max-height: 400px"
      >

      <h1 class="mt-10 text-lg font-medium text-dark-purple">
        {{ customTitle || errorTitle }}
      </h1>
      <p
        v-if="customMessage || errorMessage"
        class="mt-5 font-medium text-grey-blue"
      >
        {{ customMessage || errorMessage }}
      </p>
      <HubButton
        v-if="showReturnHome"
        class="mt-6"
        :label="$t(`errors.generic.buttonText`)"
        @click="handleError"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const { t } = useI18n()
const props = withDefaults(defineProps<{
  parentClasses?: string
  error?: NuxtError
  statusCode?: number
  customTitle?: string
  customMessage?: string
  customSubtitle?: string
  showReturnHome?: boolean
}>(),
{
  parentClasses: 'min-h-screen px-6 py-24 sm:py-32 lg:px-8',
  showReturnHome: true
})

const errorTitle: Ref<string> = ref('')
const errorMessage: Ref<string | undefined> = ref()

watch(
  () => props,
  () => {
    errorMessage.value = undefined

    if (props.error) {
      errorTitle.value = props.error.message || props.error.statusMessage || t('errors.500.title')
    }

    if (props.statusCode) {
      if ([500, 404, 403].includes(props.statusCode as number)) {
        errorTitle.value = t(`errors.${props.statusCode}.title`)
        errorMessage.value = t(`errors.${props.statusCode}.message`)
      }
    }
  },
  {
    immediate: true,
    deep: true
  }
)

function handleError() {
  clearError({ redirect: '/' })
}
</script>
