/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  HubSession,
  HubUser,
  HubUserPreference,
  UserInfo
} from '~/types'
import type { HubDashboardBaseModal } from '~/types/configuration'

export const useUserStore = defineStore('user', () => {
  const dashboardStore = useDashboardStore()
  const {
    allDashboards,
    userDefaultDashboard,
    currentOrganisationGroupDefaultDashboards,
    groupDefaultDashboards
  } = storeToRefs(dashboardStore)

  const runtimeConfig = useRuntimeConfig()
  const identityServer = runtimeConfig.public.identityProviderBase

  const currentUser: Ref<HubUser | undefined> = ref()
  const currentUserPreferences: Ref<Array<HubUserPreference>> = ref([])

  const getCurrentUser: ComputedRef<HubUser | undefined> = computed(() => currentUser.value)
  const getCurrentUserPreferences: ComputedRef<Array<HubUserPreference>> = computed(() => currentUserPreferences.value)

  const userHomePage: ComputedRef<string> = computed(() => {
    const baseDashboardUrl = '/dashboard/'
    if (userDefaultDashboard.value) {
      return baseDashboardUrl + userDefaultDashboard.value.id
    }

    if (currentOrganisationGroupDefaultDashboards.value.length > 0) {
      return baseDashboardUrl + currentOrganisationGroupDefaultDashboards.value[0].id
    }

    if (groupDefaultDashboards.value.length > 0) {
      return baseDashboardUrl + groupDefaultDashboards.value[0].id
    }

    if (allDashboards.value.length > 0) {
      const firstNormalDashboard = allDashboards.value.find((dashboard: HubDashboardBaseModal) =>
        !dashboard.type || dashboard.type === 'default'
      )

      if (firstNormalDashboard) {
        return '/dashboard/' + firstNormalDashboard.id
      }
    }

    return '/'
  })

  async function fetchUserInfo(accessToken: string, tryRefresh = true) {
    try {
      const userInfo = await $fetch<UserInfo>(`${identityServer}/connect/userinfo`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      return userInfo
    } catch (error: any) {
      if (error.statusCode === 401 && tryRefresh) {
        const { data, getSession } = useAuth()
        const session = data.value as HubSession

        if (session?.user?.impersonatorName) {
          useRouter().push('/login?signOut=true')
          return
        }

        await getSession()
        return await fetchUserInfo(session.accessToken, false)
      }

      throw new Error('Failed to fetch user info')
    }
  }

  async function fetchCurrentUserPermissions() {
    try {
      const { data } = useAuth()
      const session = data.value as HubSession
      const currentUserRole = await $hubFetch('/api/v4/roles/users/current') as {
        roleId: string
        description: string
        permissionIds: Array<string>
      }

      if (!currentUserRole) {
        return
      }

      currentUser.value = {
        ...session.user,
        roleId: currentUserRole.roleId,
        roleName: currentUserRole.description,
        permissions: currentUserRole.permissionIds
      }
    } catch {
      return undefined
    }
  }

  async function fetchCurrentUser() {
    if (currentUser.value) {
      return currentUser.value
    }

    try {
      await fetchCurrentUserPermissions()
      return currentUser.value
    } catch (error: any) {
      console.warn(error.value?.statusMessage || error)
    }
  }

  async function fetchCurrentUserPreferences() {
    try {
      currentUserPreferences.value = (await $hubFetch('/api/v4/users/current/preferences')) as Array<HubUserPreference>

      return currentUserPreferences.value
    } catch (error: any) {
      console.warn(error.value?.statusMessage || error)
    }
  }

  function fetchImpersonatorName() {
    try {
      const { data } = useAuth()
      const session = data.value as HubSession
      return session?.user?.impersonatorName
    } catch {
      return undefined
    }
  }

  return {
    currentUser,
    currentUserPreferences,

    getCurrentUser,
    getCurrentUserPreferences,
    userHomePage,

    fetchCurrentUser,
    fetchCurrentUserPreferences,
    fetchCurrentUserPermissions,
    fetchUserInfo,
    fetchImpersonatorName
  }
})
